import { graphql, useStaticQuery } from "gatsby"
import ImgContent from "../imgContent"

const ContentBA = () => {
  const downloadFile = useStaticQuery(graphql`
    query QueryDownloadBABE {
      file(relativePath: { eq: "BABE-Service.xlsx" }) {
        publicURL
        name
      }
    }
  `)

  return (
    <section
      className="elementor-element elementor-element-5ac5a52 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
      data-id="5ac5a52"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"tilt"}'
    >
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-10e9a05 elementor-column elementor-col-33 elementor-top-column elementor-emir"
            data-id="10e9a05"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div
              className="elementor-column-wrap elementor-element-populated"
              id="content-serviceBA-width"
            >
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-c3f3cb1 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                  data-id="c3f3cb1"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1
                      className="elementor-heading-title elementor-size-default title-size"
                      id="padding-title-main"
                    >
                      Bioavailability & Bioequivalence (BA/BE) Studies
                    </h1>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-d455920 elementor-widget elementor-widget-text-editor"
                  data-id="d455920"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container font-serviceBA-content">
                    <div className="elementor-text-editor elementor-clearfix">
                      <div className="lqd-lines split-unit lqd-unit-animation-done height-service-BA-konten">
                        <p>
                          Bioavailability/Bioequivalence Services at Pharma
                          Metric Lab are conducted according to GCP and ISO
                          17025/GLP compliance. Our facilities had been audited
                          by BPOM (Indonesian FDA), National Accreditation Body
                          (KAN) and customers from Indonesia and Malaysia. Our
                          BA/BE report has been accepted by Regulatory Authority
                          in Indonesia, Malaysia and Philippines. Our services
                          comprises of regulatory consulting, protocol design,
                          ethics committee submissions, import license of
                          sample, subject screening and management, method
                          development and validation, data management,
                          statistical analysis and report writing.
                        </p>
                        <p>
                          BA/BE studies are conducted in house at Pharma Metric
                          Labs or at selected hospital in Jakarta, depending on
                          customer request. Our In house clinical facilities has
                          40 beds for volunteers located at Central Jakarta,
                          near to hospital. We have a database of over 500
                          volunteers and have areas for screening, clinics,
                          recreation and dining rooms, as well as pharmacy with
                          emergency medicines.
                        </p>
                        <p>
                          We have an in-house, GLP-compliant bioanalytical
                          laboratory with an LCMS/MS, UPLC and 2 HPLCs : Waters
                          Triple Quadrapole LC-MS/MS system, Waters UPLC and
                          Alliance Waters. We have developed a library of
                          validated bioanalytical methods that have been used in
                          prior BA/BE studies. At the moment we have 53 bio
                          analytical methods in the wide range of therapeutic
                          areas such as Gastro Intestinal Tract, Cardiovascular,
                          Anti Diabetic, Antibiotic, Anti Fungi,
                          Musculoskeletal, Central Nervous System, Analgesic,
                          Respiratory, Anti hystamine, Health Supplement, etc.
                          Nevertheless, we continuously validate new
                          bioanalytical methods to address a new customer need
                          and anticipate future customer needs.
                        </p>

                        {/* <p>To find out more about our BA/BE services,</p>

                        <a
                          className="button"
                          id="warnaButton"
                          // href={`BABE-Service.xlsx`}
                          href={downloadFile.file.publicURL}
                          download
                        >
                          Click Here{" "}
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-bd28f10 elementor-column elementor-col-66 elementor-top-column elementor-img-BA"
            data-id="bd28f10"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-eded2fd animation mobile_static elementor-widget__width-initial elementor-widget elementor-widget--image elementor-ImageFixed"
                  data-id="eded2fd"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute","":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image image-serviceBA-width image-margin-service-BA">
                      {/* <img
												width={1440}
												height={956}
												src={img1}
												className="attachment-full size-full"
												alt=""
												// sizes="(max-width: 1440px) 100vw, 1440px"
											/> */}

                      <ImgContent pmlImg="babe_flow.png" className="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentBA
