import ImgComponent from "../imgComponent"

const Banner = () => {
  return (
    <section
      className="elementor-element elementor-element-fb7667e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
    >
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-badcbdc elementor-column elementor-col-100 elementor-top-column"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-7bef6e7 elementor-widget elementor-widget-avante-slider-parallax"
                  data-element_type="widget"
                  data-widget_type="avante-slider-parallax.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="slider-parallax-wrapper"
                      data-autoplay={8000}
                      data-pagination={0}
                      data-navigation={1}
                    >
                      <div className="slider_parallax_inner">
                        <div className="slider_parallax_slides">
                          <div className="slide is-active ">
                            <div className="slide-content align-center">
                              <div className="standard-wrapper">
                                <div className="caption">
                                  <div className="title title-banner">
                                    <h2>Bioavailability & </h2>
                                    <h2>Bioequivalence Studies</h2>
                                  </div>
                                  <div className="text">
                                    We give you our best end-to-end services
                                    with our years of experiences
                                  </div>
                                  <a
                                    className="button"
                                    id="warnaButton"
                                    href="/HelpCenter/ContactUs"
                                  >
                                    Get In Touch{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="image-container">
                              <ImgComponent
                                pmlImg="banner_service_babe.jpg"
                                className="image"
                              />
                              {/* <img
																// ** //
																src={img01}
																alt=""
																className="image"
															/> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
